/* Contacts */
.contacts{
  @include main-grid();
  box-sizing:border-box;
  border-top:1px solid var(--color-black-600);
  padding-left:recalculate(80);
  padding-right:recalculate(322);
  z-index:10;
  height:100vh;
  overflow:hidden;
  &-map{
    margin-left:recalculate(-80);
    grid-column:1/8;
    grid-row:1/3;
    z-index:10;
  }
  &-content{
    grid-column:1/5;
    grid-row:1/3;
    z-index:20;
    box-sizing:border-box;
    padding:recalculate(65) recalculate(72) recalculate(85) recalculate(72);
    margin-left:recalculate(87);
    background-color:#fff;
  }
  h2{
    font-size:recalculate(32);
    line-height:recalculate(44);
    font-weight:600;
    letter-spacing:0.01em;
    color:var(--color-black-100);
    margin-bottom:recalculate(31);
  }
  &-item{
    box-sizing:border-box;
    border-bottom:1px solid var(--color-black-700);
    padding-bottom:recalculate(19);
    svg{
      display:inline;
      width:12px; 
      position:relative;
      top:2px;
    }
    & + &{
      margin-top:recalculate(21);
    }
    h3{
      margin-bottom:recalculate(15);
      font-size:recalculate(14);
      font-weight:600;
      letter-spacing:0.02em;
      color:var(--color-black-200);
      text-transform:uppercase;
    }
    address{
      font-size:recalculate(12);
      line-height:recalculate(20);
      letter-spacing:0.02em;
      color:var(--color-black-200);
    }
    a{
      display:block;
      font-size:recalculate(12);
      line-height:recalculate(20);
      letter-spacing:0.02em;
      color:var(--color-green-100);
    }
  }
}

@media (max-width:568px){
  .contacts{
    padding-top:79px;
    @include mobile-grid();
    border-top:1px solid var(--color-black-700);
    padding-left:32px;
    padding-right:32px;
    height:auto;
    &-map{
      margin-top: 61px;
      width:calc(100% + 64px);
      height:304px;
      margin-left:-32px;
      grid-column:1;
      grid-row:3;
    }
    &-content{
      grid-column:1;
      grid-row:1;
      padding:0;
      margin-left:0;
    }
    h2{
      font-size:28px;
      line-height:32px;
      margin-bottom:44px;
    }
    &-item{
      padding-bottom:19px;
      & + &{
        margin-top:21px;
      }
      h3{
        margin-bottom:15px;
        font-size:14px;
      }
      address{
        font-size:12px;
        line-height:20px;
      }
      a{
        display:block;
        font-size:12px;
        line-height:20px;
      }
    }
  }
}