/* Header */
$header: 'header';
.header{
  height:100vh;
  display:grid;
  grid-template-columns:1fr;
  grid-template-rows:recalculate(96) 1fr recalculate(96);
  &--white{
    position:absolute;
    top:0;
    right:recalculate(72);
    .#{$header}-nav-link{
      &:after{
      }
    }
    .#{$header}-contacts-item{
    }
  }
  &--black{
    position:fixed;
    top:0;
    right:recalculate(72);
    z-index:10;
    .#{$header}-nav-link{
      color:var(--color-black-500);
      opacity:1;
      transition:color .2s ease-out;
      &.active, &:hover{
        transition:color .2s ease-in;
        color:var(--color-black-200);
      }
      &:after{
        border-top:1px solid var(--color-black-600);
      }
    }
    .#{$header}-contacts-item{
      color:var(--color-black-500);
      opacity:1;
    }
  }
  .btn{
    margin-top:recalculate(56);
    grid-row:1;
  }
  &-nav{
    grid-row:2;
    align-self:center;
    /*position:absolute;
    top:50%;
    transform:translateY(-50%);
    right:0;*/
    @include list-axis-y();
    &-link{
      display:inline-block;
      text-align:right;
      font-size:recalculate(12);
      line-height:recalculate(24);
      letter-spacing:.03em;
      color:#fff;
      opacity:.75;
      text-transform:uppercase;
      font-weight:500;
      position:relative;
      &:after{
        content:'';
        position:absolute;
        top:50%;
        transform:translate(recalculate(32), -50%);
        width:recalculate(40);
        border-top:1px solid rgba(255,255,255,0.3);
      }
      &:not(:last-of-type){
        margin-bottom:recalculate(16);
      }
    }
  }
  &-contacts{
    grid-row:3;
    &-item{
      font-size:recalculate(12);
      line-height:recalculate(24);
      letter-spacing:.03em;
      color:#fff;
      opacity:.85;
      a{
        display:block;
        text-align:right;
      }
    }
  }
}

@media (max-width:568px){
  .header{
    display:none !important;
    visibility:hidden !important;
    opacity:0 !important;
  }
}