/* Buttons */
$btn: 'btn';

.btn{
  display:inline-block;
  box-sizing:border-box;
  border-radius:2px;
  overflow:hidden;
  position:relative;
  &--hero{
    padding-left:recalculate(32);
    padding-right:recalculate(32);
    border:1px solid rgba(255, 255, 255, 0.5);
    height:recalculate(48);
    .#{$btn}-text{
      line-height:recalculate(48);
      color:#fff;
      opacity:.85;
    }
    .#{$btn}-effect{
      background-color:rgba(255,255,255,0.3);
    }
  }
  &--header{
    padding-left:recalculate(24);
    padding-right:recalculate(24);
    border:1px solid rgba(255, 255, 255, 0.5);
    height:recalculate(40);
    .#{$btn}-text{
      line-height:recalculate(40);
      color:#F9FCF7;
    }
    .#{$btn}-effect{
      background-color:rgba(255,255,255,0.3);
    }
  }
  &--faded{
    height:recalculate(40);
    padding-left:recalculate(24);
    padding-right:recalculate(24);
    background-color:var(--color-green-400);
    &:hover{
      .#{$btn}-text{
        color:var(--color-black-400);
        transition:color .2s ease-out;
      }
    }
    .#{$btn}-text{
      line-height:recalculate(40);
      color:var(--color-green-100);
      transition:color .2s ease-in;
    }
    .#{$btn}-effect{
      background-color:var(--color-black-700);
    }
  }
  &--green{
    height:recalculate(40);
    padding-left:recalculate(24);
    padding-right:recalculate(24);
    background-color:var(--color-green-200);
    .#{$btn}-text{
      line-height:recalculate(40);
      color:var(--color-green-500);
    }
    .#{$btn}-effect{
      background-color:var(--color-black-500);
    }
  }
  &-text{
    display:block;
    font-weight:600;
    font-size:recalculate(12);
    text-transform:uppercase;
    letter-spacing:.03em;
    z-index:20;
    position:relative;
  }
  &:hover{
    .#{$btn}-effect{
      transform: translate3d(-50%, -50%, 0) scale(1);
    }
  }
  &-effect{
    width: 450px;
    height: 450px;
    display: block;
    position: absolute;
    z-index: 10;
    content: '';
    border-radius: 50%;
    transition: transform 0.4s ease-in-out;
    transform: translate3d(-50%, -50%, 0) scale(0);
  }
}


@media (max-width:568px){
  .btn{
    &--hero{
      padding-left:32px;
      padding-right:32px;
      height:48px;
      .#{$btn}-text{
        line-height:48px;
      }
    }
    &--header{
      padding-left:24px;
      padding-right:24px;
      height:40px;
      .#{$btn}-text{
        line-height:40px;
      }
    }
    &--faded{
      height:40px;
      padding-left:24px;
      padding-right:24px;
      .#{$btn}-text{
        line-height:40px;
      }
    }
    &--green{
      height:40px;
      padding-left:24px;
      padding-right:24px;
      .#{$btn}-text{
        line-height:40px;
      }
    }
    &-text{
      font-size:12px;
    }
  }
}