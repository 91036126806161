/* Color Variables */
:root{
  // black
  --color-black-100: #1F201D;
  --color-black-200: #292B27;
  --color-black-300: #343630;
  --color-black-400: #484A45;
  --color-black-500: #A4A6A0;
  --color-black-600: #CBD0C8;
  --color-black-700: #E2E5DC;
  --color-black-800: #EBECE9;

  // green
  --color-green-100: #599735;
  --color-green-200: #6BB540;
  --color-green-300: #C8DCBC;
  --color-green-400: #E2F0DB;
  --color-green-500: #F9FCF7;

  // Utility
  /*--color-background: #EAEBEA;
  --color-red: #E05252;*/
}