/* Form */
.form{
  margin-top: recalculate(33);
  form{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &-gdpr{
    display:block;
    text-align:right;
    font-size:recalculate(12);
    line-height:recalculate(20);
    letter-spacing:0.03em;
    color:var(--color-black-300);
    margin-top:recalculate(19);
    a{
      color:var(--color-green-200);
    }
  }
  &--contacts{
    .btn{
      margin-top:recalculate(24);
    }
  }
  &--modal{
    margin-top:recalculate(28);
    .btn{
      margin-top:recalculate(24);
    }
  }
  [type="text"]{
    width:100%;
    display:block;
    font-size:recalculate(14);
    line-height:recalculate(16);
    letter-spacing:0.03em;
    color:var(--color-black-200);
    height:recalculate(40);
    box-sizing:border-box;
    border-bottom:1px solid var(--color-green-200);
    &::placeholder{
      color:var(--color-black-200);
    }
    & + [type="text"] {
      margin-top:recalculate(16);
    }
  }
  button{

  }
}

@media (max-width:568px){
  .form{
    margin-top: 33px;
    &-gdpr{
      font-size:12px;
      line-height:20px;
      margin-top:19px;
    }
    &--contacts{
      .btn{
        margin-top:24px;
      }
    }
    &--modal{
      margin-top:28px;
      .btn{
        margin-top:24px;
      }
    }
    [type="text"]{
      font-size:14px;
      line-height:16px;
      height:40px;
      & + [type="text"] {
        margin-top:16px;
      }
    }
  }
}