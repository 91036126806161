/* Production */
.production {
  @include main-grid();
  box-sizing: border-box;
  padding-left: recalculate(80);
  padding-right: recalculate(322);
  z-index: 10;
  .dropdown-nav{
    display:none;
  }
  h2 {
    grid-column: 1/3;
    grid-row: 1;
    font-weight: 600;
    font-size: recalculate(32);
    line-height: recalculate(44);
    letter-spacing: .01em;
    color: var(--color-black-100);
    position: sticky;
    margin-top: recalculate(57);
    top: recalculate(57);
  }

  &-nav {
    z-index: 30;
    grid-column: 3/8;
    grid-row: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: sticky;
    margin-top: recalculate(65);
    padding-bottom: recalculate(55);
    top: recalculate(65);
    background-color: #fff;

    &:before {
      content: '';
      width: 100%;
      height: recalculate(65);
      top: recalculate(-65);
      background-color: #fff;
      //height:calc(100% + 100px);
      z-index: -1;
      position: absolute;
      //top:-100px;
      left: 0;
    }

    &-link {
      display: inline-block;
      color: var(--color-black-500);
      font-size: recalculate(12);
      line-height: recalculate(24);
      letter-spacing: -.01em;
      margin-right: recalculate(20);
      margin-bottom: recalculate(8);
      font-weight: 500;
      position: relative;
      text-transform: uppercase;
      transition: color .2s ease-in;

      &:after {
        width: 100%;
        display: block;
        content: '';
        position: absolute;
        bottom: 3px;
        border-top: 1px solid #fff;
        transition: border-top .2s ease-in;
      }

      &.active, &:hover {
        color: var(--color-green-100);
        transition: color .2s ease-out;

        &:after {
          border-top: 1px solid var(--color-green-200);
          transition: border-top .2s ease-out;
        }
      }
    }
  }

  &-media {
    grid-column: 1/5;
    grid-row: 2;
    height: 60vh;
    background-color: #e4eae1;
    position: sticky;
    top: recalculate(212);
    z-index: 40;
    &-item {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .25s ease-in;
      cursor: pointer;
      z-index:10;
      &-img {
        height: inherit;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.active {
        opacity: 1;
        transition: opacity .25s ease-out;
        z-index:20;
      }
    }
    &-all{
      width:100%;
      position:absolute;
      left:0;
      bottom:recalculate(-41);
      height:recalculate(24);
      &:after{
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        display:block;
        border-top:1px solid var(--color-green-300);
        width:100%;
        content:'';
        z-index:10;
      }
      a {
        position:absolute;
        top:0;
        left:50%;
        transform:translateX(-50%);
        display:inline-block;
        background-color:#fff;
        font-weight:500;
        font-size:recalculate(10);
        line-height:recalculate(24);
        letter-spacing:0.03em;
        color:var(--color-green-100);
        text-transform:uppercase;
        z-index:20;
        box-sizing:border-box;
        padding-left:recalculate(24);
        padding-right:recalculate(24);
      }
    }
  }

  &-content {
    grid-column: 5/8;
    grid-row: 2;
    margin-top: recalculate(24);
    position: relative;
    box-sizing: border-box;
    padding-bottom: 160px;
  }

  &-item {
    z-index: 10;
    &-media{
      display:none;
    }
    & + & {
      margin-top: recalculate(80);
    }

    h3 {
      margin-bottom: recalculate(28);
      font-weight: 600;
      color: var(--color-black-100);
      font-size: recalculate(28);
      line-height: recalculate(32);
      letter-spacing: .01em;
    }

    & > h4 {
      font-weight: 500;
      color: var(--color-black-200);
      font-size: recalculate(14);
      line-height: recalculate(16);
      margin-bottom: recalculate(7);
    }

    & > p {
      font-size: recalculate(12);
      line-height: recalculate(20);
      letter-spacing: 0.02em;
      color: var(--color-black-200);
    }

    &-info {
      margin-bottom: recalculate(40);
      margin-top: recalculate(29);
      box-sizing: border-box;
      padding-top: recalculate(4);
      padding-bottom: recalculate(1);
      padding-left: recalculate(32);
      border-left: 1px solid var(--color-green-200);

      h4 {
        font-weight: 500;
        color: var(--color-black-200);
        font-size: recalculate(14);
        line-height: recalculate(16);
        margin-bottom: recalculate(7);
      }

      p {
        font-size: recalculate(12);
        line-height: recalculate(20);
        letter-spacing: 0.02em;
        color: var(--color-black-200);

        & + h4 {
          margin-top: recalculate(25);
        }
      }
    }
  }
}

@media (max-width:568px){
  .production {
    @include mobile-grid();
    padding-left: 32px;
    padding-right: 32px;
    h2 {
      grid-column: 1;
      font-size: 28px;
      line-height: 32px;
      position: initial;
      margin-top: 72px;
      top: 0;
      grid-row:1;
    }
    .dropdown-nav{
      margin-top:16px;
      display:block;
      grid-row:2;
    }
    &-nav, &-media{
      display:none;
    }
    &-content {
      grid-column: 1;
      grid-row: 3;
      margin-top: 48px;
      padding-bottom: 80px
    }
    &-item {
      &-media{
        margin-bottom:34px;
        display:block;
        width:calc(100% + 64px);
        margin-left:-32px;
        height:264px;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center;
      }
      & + & {
        margin-top: 64px;
      }
      h3 {
        margin-bottom: 26px;
        font-size: 22px;
        line-height: 32px;
      }
      & > h4 {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 7px;
      }
      & > p {
        font-size: 12px;
        line-height: 20px;
      }
      &-info {
        margin-bottom:41px;
        margin-top: 29px;
        padding-top: 4px;
        padding-bottom: 1px;
        padding-left: 32px;
        h4 {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 7px;
        }
        p {
          font-size: 12px;
          line-height: 20px;
          & + h4 {
            margin-top: 25px;
          }
        }
      }
    }
  }
}