/* Default settings for colors and fonts */
html{
  font-family:var(--font-gotham-pro);
  font-weight:400;
  color:var(--color-black-200);
}

::selection {
  background-color:var(--color-green-400);
}

h1, h2, h3, h4, h5, h6, p{
  display:block;
}

a{
  font-family:inherit;
  color:inherit;
  text-decoration:none;
}