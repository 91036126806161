/* About */
.about{
  @include main-grid();
  box-sizing:border-box;
  padding-left:recalculate(80);
  padding-right:recalculate(322);
  min-height:100vh;
  place-content:start;
  padding-bottom:recalculate(80);
  h2{
    grid-column:4/8;
    grid-row:1;
    font-weight:600;
    font-size:recalculate(32);
    line-height:recalculate(44);
    letter-spacing:.01em;
    color:var(--color-black-100);
    margin-top:recalculate(89);
    margin-bottom:recalculate(24);
  }
  p{
    grid-column:4/8;
    font-size:recalculate(16);
    line-height:recalculate(28);
    letter-spacing:0.02em;
    color:var(--color-black-400);
    & + p{
      margin-top:recalculate(28);
    }
    & + ul{
      margin-top:recalculate(16);
    }
  }
  ul{
    grid-column:4/8;
    li{
      font-size:recalculate(16);
      line-height:recalculate(28);
      letter-spacing:0.02em;
      color:var(--color-black-400);
      box-sizing:border-box;
      padding-left:recalculate(20);
      position:relative;
      &:before{
        position:absolute;
        display:block;
        content:'';
        width:8px;
        top:13px;
        left:0;
        border-top: 1px solid var(--color-green-200);
      }
      &:not(:last-of-type){
        margin-bottom:recalculate(4);
      }
    }
    & + p{
      margin-top:recalculate(28);
    }
  }
  &-media{
    width:100%;
    place-self:start;
    grid-column:1/4;
    grid-row:1/5;
    height:50.5vh;
    margin-top:recalculate(72);
    overflow:hidden;
    box-sizing:border-box;
    padding-bottom:recalculate(52);
    position:sticky;
    top:recalculate(72);
    &-prev, &-next{
      position:absolute;
      top:0;
      width:recalculate(64);
      height:calc(100% - (52 / 1440 * 100vw));
      cursor:pointer;
      background-size:recalculate(16) auto;
      background-position:center;
      background-repeat:no-repeat;
      z-index:100;
    }
    &-prev{
      left:0;
      background-image:url("../img/i-arrow-left.svg");
    }
    &-next{
      right:0;
      background-image:url("../img/i-arrow-right.svg");
    }
    .swiper-container-pointer-events{
      height:100%;
    }
    &-pagination{
      width:auto !important;
      position:absolute;
      right:0;
      left:initial;
      bottom:0 !important;
      display:inline-block;
      font-size:recalculate(16);
      line-height:24px;
      letter-spacing:-0.06em;
      color:var(--color-black-400);
      text-align:right;
    }
    &-scrollbar{
      width:calc(100% - (53 / 1440 * 100vw));
      height:24px;
      position:absolute;
      left:0;
      bottom:0;
      cursor:pointer;
      &:before{
        position:absolute;
        display:block;
        width:100%;
        content:'';
        border-top:1px solid var(--color-green-300);
        z-index:-1;
        top:50%;
        transform:translateY(-50%);
      }
      .swiper-scrollbar-drag{
        top:calc(50% - 1.5px);
        height:recalculate(4);
        background-color:var(--color-green-100);
        border-radius:2px;
      }
    }
    &-item{
      width:100%;
      height:100%;
      background-size:cover;
      background-repeat:no-repeat;
      background-position:center;
      //background-color:#e4eae1;

    }
  }
  &-partners{
    margin-top:recalculate(70);
    grid-column:4/8;
    h3{
      margin-bottom:recalculate(32);
      font-weight:600;
      letter-spacing:.02em;
      font-size:recalculate(16);
      line-height:recalculate(24);
      color:var(--color-black-200);
      text-transform:uppercase;
    }
    &-grid{
      display:grid;
      grid-template-columns:repeat(4, minmax(0,1fr));
      grid-template-rows:repeat(3, minmax(0,auto));
      column-gap:recalculate(32);
      row-gap:recalculate(32);
      img{
        max-width:100%;
        max-height:recalculate(52);
        width:auto;
        height:auto;
        place-self:center;
      }
    }
  }
}

@media (max-width:568px){
  .about{
    padding-bottom:77px;
    @include mobile-grid();
    padding-left:32px;
    padding-right:32px;
    height:auto;
    h2{
      grid-column:1;
      grid-row:1;
      font-size:28px;
      line-height:32px;
      margin-top:72px;
      margin-bottom:25px;
    }
    ul{
      grid-column:1;
      & + p{
        margin-top:24px;
      }
      li{
        font-size:16px;
        line-height:28px;
        padding-left:20px;
        &:not(:last-of-type){
          margin-bottom:4px;
        }
      }
    }
    p{
      grid-column:1;
      font-size:16px;
      line-height:28px;
      color:var(--color-black-400);
      & + p {
        margin-top:24px;
      }
    }
    &-partners{
      margin-top:46px;
      grid-column:1;
      grid-row:4;
      h3{
        margin-bottom:25px;
        font-size:16px;
        line-height:24px;
      }
      &-grid{
        grid-template-columns:repeat(2, minmax(0,1fr));
        column-gap:0;
        grid-template-rows:repeat(7, minmax(0, 80px));
        row-gap:0;
        box-sizing:border-box;
        border:1px solid var(--color-black-700);
        position:relative;
        background-size: auto auto;
        background-color: rgba(255, 255, 255, 1);
        background-image: repeating-linear-gradient(0deg, transparent, transparent 80px, var(--color-black-700) 80px, var(--color-black-700) 81px );
        &:after{
          position:absolute;
          top:0;
          content:'';
          display:block;
          height:100%;
          border-right:1px solid var(--color-black-700);
          left:50%;
          transform:translateX(-50%);
        }
        img{
          max-width:calc(100% - 56px);
          max-height:40px;
        }
      }
    }
    &-media{
      width:calc(100% + 64px);
      margin-left:-32px;
      grid-column:1;
      grid-row:5;
      height:228px;
      margin-top:72px;
      padding-bottom:44px;
      top:0;
      position:relative;
      margin-bottom:28px;
      &-prev, &-next{
        width:52px;
        height:184px;
        background-size:12px auto;
      }
      &-swiper{
        height:100%;
        overflow:hidden;
        .swiper-wrapper{
          height:100%;
        }
      }
      &-pagination{
        width:auto !important;
        position:absolute;
        right:32px;
        left:initial;
        bottom:0 !important;
        display:inline-block;
        font-size:16px;
        line-height:24px;
        letter-spacing:-0.06em;
        color:var(--color-black-400);
        text-align:right;
      }
      &-scrollbar{
        width:calc(100% - 122px);
        height:24px;
        margin-left:32px;
        position:absolute;
        left:0;
        bottom:0;
        cursor:pointer;
        &:before{
          position:absolute;
          display:block;
          width:100%;
          content:'';
          border-top:1px solid var(--color-green-300);
          z-index:-1;
          top:50%;
          transform:translateY(-50%);
        }
        .swiper-scrollbar-drag{
          height:4px;
          top:calc(50% - 2px);
          position:relative;
        }
      }
      &-item{
        width:100%;
        height:100%;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center;
      }
    }
  }
}