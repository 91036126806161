/* font gotham-pro */
@font-face {
  font-family: 'gotham-pro';
  font-style: normal;
  font-weight: 400;
  font-display:swap;
  src:  local('gotham-pro-regular'),
        url(../fonts/gotham-pro/gotham-pro-regular.ttf) format('truetype');
}

@font-face {
  font-family: 'gotham-pro';
  font-style: normal;
  font-weight: 500;
  font-display:swap;
  src:  local('gotham-pro-medium'),
        url(../fonts/gotham-pro/gotham-pro-medium.ttf) format('truetype');
}

@font-face {
  font-family: 'gotham-pro';
  font-style: normal;
  font-weight: 600;
  font-display:swap;
  src:  local('gotham-pro-bold'),
        url(../fonts/gotham-pro/gotham-pro-bold.ttf) format('truetype');
}

:root{
  --font-gotham-pro: 'gotham-pro', BlinkMacSystemFont, Segoe UI, Roboto, Cantarell, Helvetica Neue, sans-serif;
}
