/* Mixins */
@mixin list-axis-x {
  display:flex;
  flex-direction:row;
}

@mixin list-axis-y {
  display:flex;
  flex-direction:column;
}

@mixin main-grid {
  display:grid;
  grid-template-columns:repeat(7, minmax(0,recalculate(87)));
  column-gap:recalculate(72);
}

@mixin mobile-grid {
  display:grid;
  grid-template-columns:1fr !important;
  column-gap:0 !important;
}