/* section: hero */
.hero{
  width:100%;
  height:100vh;
  position:relative;
  @include main-grid();
  grid-template-rows:recalculate(96) 1fr recalculate(96);
  box-sizing:border-box;
  padding-left:recalculate(80);
  padding-right:recalculate(322);
  z-index:20;
  overflow:hidden;
  &-menu-m{
    display:none;
  }
  &-logo{
    place-self:start;
    img{
      grid-column:1/3;
      margin-top:recalculate(36);
      height:recalculate(24);
    }
  }
  &-logo, &-content, &-contacts{
    position:relative;
    z-index:10;
  }
  h1{
    grid-column:1/3;
    font-size: recalculate(40);
    letter-spacing:0.01em;
    color:#ffffff;
    line-height: recalculate(44);
    font-weight:600;
    font-family:var(--font-gotham-pro);
    margin-bottom:recalculate(22);
  }
  p{
    font-size:recalculate(16);
    line-height:recalculate(28);
    letter-spacing:.02em;
    color:#fff;
    opacity:.85;
    margin-bottom:recalculate(43);
  }
  &-logo{
    grid-column:1/3;
    &-img{}
  }
  &-content{
    grid-column:1/6;
    place-self:center start;
    box-sizing:border-box;
    padding-left:recalculate(87);
  }
  &-contacts{
    //width:100%;
    position:absolute;
    //bottom:recalculate(47);
    //bottom:recalculate(47);
    grid-row:3;
    &--left{
      text-align:left;
      //bottom:recalculate(53);
      left:recalculate(80);
      address{
        line-height:recalculate(24);
      }
    }
    &--right{
      right:recalculate(72);
      text-align:right;
    }
    &-item{
      font-size:recalculate(12);
      line-height:recalculate(24);
      letter-spacing:.03em;
      color:#fff;
      opacity:.85;
    }
    a + a{
      margin-left:recalculate(12);
    }
  }
  &-video{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    overflow:hidden;
    z-index:-10;
    &:after{
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background: linear-gradient(255deg, rgba(0, 0, 0, 0.115) 0%, rgba(0, 0, 0, 0.45) 65%, rgba(0, 0, 0, 0.175) 100%);
      //opacity: .55;
    }
    &-media{
      min-width: 150%;
      min-height: 150%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media (max-width:568px){
  .hero{
    height:auto;
    min-height:100vh;
    @include mobile-grid();
    grid-template-rows:64px auto auto !important;
    padding-left:32px;
    padding-right:32px;
    padding-bottom:39px;
    &-menu-m{
      position:relative;
      display:flex;
      flex-direction:row-reverse;
      align-items:center;
      justify-items:flex-end;
      width: calc(100% + 64px);
      margin-left: -32px;
      height:64px;
      background-color:rgba(255,255,255,0.15);
      backdrop-filter:blur(10px);
      z-index:10;
      grid-column:1;
      grid-row:1;
    }
    &-burger{
      margin-right:32px;
      width:32px;
      height:16px;
      background:transparent url("../img/i-burger.svg") no-repeat center;
      background-size:contain;
    }
    &-logo{
      position:relative;
      place-self:start;
      z-index:20;
      img{
        grid-column:1;
        margin-top:24px;
        height:16px;
      }
    }
    h1{
      grid-column:1;
      font-size:32px;
      line-height: 40px;
      margin-bottom:18px;
    }
    p{
      font-size:16px;
      line-height:28px;
      margin-bottom:51px;
    }
    &-logo{
      grid-column:1;
      grid-row:1;
    }
    &-content{
      grid-column:1;
      grid-row:2;
      padding-left:0;
      margin-top:91px;
      margin-bottom:73px;
    }
    &-contacts{
      grid-row:3;
      position:relative;
      bottom:initial !important;
      &--left{
        grid-row:3;
        left:initial;
        address{
          line-height:24px;
        }
      }
      &--right{
        grid-row:4;
        right:initial;
        text-align:left;
      }
      &-item{
        font-size:12px;
        line-height:24px;
      }
      a + a{
        margin-left:12px;
      }
    }
    &-video{
      &-media{
        min-width: 400%;
        min-height: 400%;
      }
    }
  }
}