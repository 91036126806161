/* Modal */
.modal{
  width: recalculate(480);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  box-sizing: border-box;
  padding: recalculate(73) recalculate(56) recalculate(73) recalculate(56);
  z-index: 110;
  transform: translateX(calc(100% + 100px));
  transition: transform .6s ease-in-out;
  &.active{
    transform: translateX(0);
  }
  &-logo{
    display:none;
  }
  &-close{
    position: absolute;
    top: recalculate(56);
    right: recalculate(56);
    cursor: pointer;
    z-index:10;
    svg{
      width:recalculate(24);
      height:recalculate(24);
    }
  }
  /*&-wrap{}
  &-content{}*/
  &-heading{
    margin-bottom:recalculate(75);
    h2{
      font-size:recalculate(32);
      line-height:recalculate(44);
      letter-spacing:0.01em;
      color:var(--color-black-100);
      font-weight:700;
    }
  }
  h3{
    font-size:recalculate(14);
    line-height:recalculate(24);
    letter-spacing:0.02em;
    color:var(--color-black-200);
    text-transform:uppercase;
    font-weight:700;
  }
  &-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:hsla(0, 0, 27,  0.7);
    z-index: 100;
    transition: opacity .15s ease-in;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: grayscale(100%) blur(4px);
    &.visible{
      visibility: visible;
    }
    &.active{
      opacity: 1;
      transition: opacity .15s ease-out;
    }
  }
}

@media (max-width:568px){
  .modal{
    width: calc(100% - 32px);
    padding: 0 40px;
    transform: translateX(calc(100% + 64px));
    &-close{
      position: absolute;
      top: 22px;
      right: 32px;
      svg{
        width:20px;
        height:20px;
      }
    }

    &-logo{
      width:calc(100% + 80px);
      margin-left:-40px;
      height:64px;
      display:flex;
      flex-direction:row;
      align-items:center;
      box-sizing:border-box;
      padding-left:40px;
      border-bottom:1px solid var(--color-black-700);
      img{
        width:auto;
        height:16px;
      }
    }
    &-nav{
      margin-top:71px;
      margin-bottom:53px;
      a{
        display:block;
        font-size:16px;
        font-weight:500;
        line-height:24px;
        letter-spacing:0.03em;
        color:var(--color-black-200);
        box-sizing:border-box;
        padding-left:48px;
        position:relative;
        text-transform:uppercase;
        &:not(:last-of-type){
          margin-bottom:28px;
        }
        &:before{
          content:'';
          display:block;
          position:absolute;
          left:0;
          top:50%;
          transform:translateY(-50%);
          width:24px;
          border-top:1px solid var(--color-green-200);
        }
      }
      & + .btn{
        height:48px;
        padding-left:32px;
        padding-right:32px;
        margin-bottom:80px;
        .btn-text{
          line-height:48px;
        }
      }
    }
    &-contacts{
      width:calc(100% + 80px);
      margin-left:-40px;
      padding:43px 40px 80px 40px;
      box-sizing:border-box;
      border-top:1px solid var(--color-black-700);
      &-item{
        & + & {
          margin-top:24px;
        }
        address{
          font-size:12px;
          line-height:20px;
          letter-spacing:0.02em;
          color:var(--color-black-200);
        }
        a{
          display:block;
          font-size:12px;
          line-height:20px;
          letter-spacing:0.02em;
          color:var(--color-green-100);
        }
      }
    }

















    &-heading{
      margin-top:42px;
      margin-bottom:30px;
      h2{
        font-size:22px;
        line-height:32px;
      }
    }
    h3{
      font-size:14px;
      line-height:24px;
    }
  }
}