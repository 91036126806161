/* Dropdown Navigation */
.dropdown-nav {
  position: relative;
  &-trigger {
    display: block;
    border: 1px solid var(--color-green-300);
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
    line-height: 48px;
    font-size: 12px;
    font-weight:500;
    letter-spacing: 0.02em;
    color: var(--color-black-400);
    padding-left: 24px;
    position: relative;
    transition: border-radius .15s ease-in-out;
    z-index: 20;
    text-transform:uppercase;
    &:active {
      border-radius: 2px 2px 0 0;
    }
    &:after {
      position: absolute;
      width: 12px;
      height: 12px;
      content: '';
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      background: transparent url("../img/i-dropdown.svg") no-repeat center;
      background-size: 12px auto;
    }
  }

  &-content {
    display: none;
    width: 100%;
    z-index: 10;
    position: absolute;
    top: 47px;
    height: 192px;
    max-height: 192px;
    border: 1px solid var(--color-green-300);
    box-sizing: border-box;
    padding-top: 25px;
    padding-bottom: 23px;
    border-radius: 0 0 2px 2px;
    overflow: scroll;
    background-color: #fff;
    a {
      display: block;
      padding-left: 24px;
      box-sizing: border-box;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: var(--color-black-400);
      text-transform:uppercase;
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }
}