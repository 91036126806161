/* Footer */
.footer{
  height:recalculate(120);
  box-sizing:border-box;
  padding-top:36px;
  @include main-grid();
  border-top:1px solid var(--color-black-700);
  &-copyright{
    grid-row:1;
    grid-column:2/4;
    span{
      font-size:recalculate(10);
      line-height:recalculate(16);
      letter-spacing:0.03em;
      color:var(--color-black-400);
      display:inline-block;
      strong{
        font-size:recalculate(10);
        line-height:recalculate(16);
        letter-spacing:0.03em;
        color:var(--color-black-300);
        font-weight:500;
      }
    }
  }
  &-info{
    grid-row:1;
    grid-column:4/7;
    p{
      font-size:recalculate(10);
      line-height:recalculate(16);
      color:var(--color-black-400);
      letter-spacing:0.03em;
    }
  }
  &-docs{
    grid-row:1;
    grid-column:7/9;
    display:flex;
    flex-direction:column;
    a{
      display:inline-block;
      font-size:recalculate(10);
      line-height:recalculate(16);
      color:var(--color-green-100);
    }
  }
}

@media (max-width:568px){
  .footer{
    height:auto;
    padding:37px 32px 35px 32px;
    @include mobile-grid;
    border-top:0;
    &-copyright{
      grid-column:1;
      grid-row:1;
      margin-bottom:16px;
      span{
        font-size:10px;
        line-height:16px;
        strong{
          font-size:10px;
          line-height:16px;
        }
      }
    }
    &-info{
      grid-row:2;
      grid-column:1;
      margin-bottom:16px;
      p{
        font-size:10px;
        line-height:16px;
      }
    }
    &-docs{
      grid-row:3;
      grid-column:1;
      a{
        font-size:10px;
        line-height:16px;
      }
    }
  }
}