/* Requirements */
.requirements{
  @include main-grid();
  box-sizing:border-box;
  padding-left:recalculate(80);
  padding-right:recalculate(322);
  background-color:var(--color-black-800);
  height:100vh;
  & > h2{
    grid-column:1/4;
    grid-row:1;
    font-weight:600;
    font-size:recalculate(32);
    line-height:recalculate(44);
    letter-spacing:.01em;
    color:var(--color-black-100);
    margin-top:recalculate(65);
  }
  & > p{
    grid-row:1;
    grid-column:4/8;
    font-size:recalculate(16);
    line-height:recalculate(28);
    letter-spacing:0.02em;
    color:var(--color-black-400);
    margin-top:recalculate(61);
  }
  &-content{
    grid-row:2;
    grid-column:1/5;
    box-sizing:border-box;
    padding-right:recalculate(86);
    h3{
      margin-bottom:recalculate(32);
      font-weight:500;
      line-height:recalculate(24);
      font-size:recalculate(22);
      letter-spacing:0.01em;
    }
  }
  &-item{
    display:flex;
    flex-direction:row;
    &:not(:last-of-type){
      margin-bottom:recalculate(28);
    }
    &-icon{
      svg{
        width:recalculate(32);
        height:recalculate(32);
      }
    }
    &-txt{
      margin-left:recalculate(24);
      font-size:recalculate(14);
      line-height:recalculate(20);
      letter-spacing:0.02em;
      color:var(--color-black-200);
    }
  }
  &-media{
    grid-row:2;
    grid-column:4/8;
    width: 100%;
    height:70vh;
    place-self: end start;
    box-sizing:border-box;
    padding-left:recalculate(86);
    &-item{
      width:100%;
      height:100%;
      background-color:#e4eae1;
    }
  }
}

@media (max-width:568px){
  .requirements{
    @include mobile-grid();
    padding-left:32px;
    padding-right:32px;
    height:auto;
    padding-bottom:72px;
    & > h2{
      grid-column:1;
      grid-row:1;
      font-size:28px;
      line-height:32px;
      margin-top:72px;
    }
    & > p{
      grid-row:2;
      grid-column:1;
      font-size:14px;
      line-height:24px;
      margin-top:24px;
    }
    &-content{
      grid-row:4;
      grid-column:1;
      padding-right:0;
      h3{
        margin-bottom:33px;
        line-height:24px;
        font-size:18px;
      }
    }
    &-item{
      &:not(:last-of-type){
        margin-bottom:24px;
      }
      &-icon{
        svg{
          margin-top:2px;
          width:32px;
          height:32px;
        }
      }
      &-txt{
        margin-left:20px;
        font-size:14px;
        line-height:24px;
        color:var(--color-black-400);
      }
    }
    &-media{
      grid-row:3;
      grid-column:1;
      width: calc(100% + 64px);
      margin-left:-32px;
      margin-top:48px;
      margin-bottom:55px;
      height:184px;
      place-self: start;
      padding-left:0;
    }
  }

}